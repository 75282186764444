import React from "react";
import './PopUp.css';
import './../../styles/defaultDesign.css';

function Toast({purpose}) {
  // if (invitation === 'yes'){
  //   return (
  //     <div className="Toast--container-invitation">
  //       <span className="Toast--content">
  //         {/* {purpose} 링크가 복사되었습니다. */}
  //         {purpose}
  //       </span>
  //     </div>
  //   )
  // } else{
  //   return (
  //     <div className="Toast--container">
  //       <span className="Toast--content">
  //         {/* {purpose} 링크가 복사되었습니다. */}
  //         {purpose}
  //       </span>
  //     </div>
  //   )
  // }
  return (
    <div className="Toast--container">
      <span className="Toast--content">
        {purpose} 복사 완료
      </span>
    </div>
  )
}

export default Toast;