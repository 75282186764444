import React, { useState } from "react";
import './HistoryDetail.css';
import '../../styles/defaultDesign.css'
import { useMediaQuery } from "react-responsive";
import EditBottomSheet from "../BottomSheet/EditBottomSheet";

function HistoryDetail({title, detail1, detail2, content, index, openEdit, openEditEducation, handleChange, number, type}) {
  const [editPC, setEditPC] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleEdit = (e) => {
    if (!isMobile) {
      setEditPC(!editPC);
      openEdit(e)
    } else {
      openEdit(e);
    }
  }

  const closeEdit = (e) => {
    e.preventDefault();
    setEditPC(false);
  }

  return(
    <div className="HistoryDetail--container">
      <div className="HistoryDetail--title-wrapper">
        <span className="HistoryDetail--title-font">{title} | {content}</span>
        <button 
          id={index}
          onClick={(e) => handleEdit(e)}
          className="HistoryDetail--button"
        />
      </div>
      <span className="b3-14-m" style={{ color: "#A8B3BD"}}>
        {detail1} ·  {detail2}
      </span>
      {
        editPC && !isMobile && (
          <EditBottomSheet
            closeEdit={closeEdit}
            openEditEducation={openEditEducation}
            handleChange={handleChange}
            index={number}
            type={type}
          />
        )
      }
    </div>
  )
}

export default HistoryDetail;