import React from "react";
import './Footer.css';
import '../../styles/defaultDesign.css';
import { useMediaQuery } from "react-responsive";

function Footer({ handleTerms }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const openNewTab = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const goToInsta = () => {
    openNewTab('https://www.instagram.com/gvzip.official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==');
  };

  const goToKaKaoTalk = () => {
    openNewTab('http://pf.kakao.com/_PxkRXn');
  }

  const goToYouTube = () => {
    openNewTab("https://www.youtube.com/@gvzip");
  }
  return(
    <div className="Footer--container">
      <div className="Footer--content-container">
        <div className="Footer--content-header">
          {
            !isMobile && (
              <div className="Footer--agreement">
                <span 
                  onClick={(e) => handleTerms(e)}
                  style={{ cursor: 'pointer' }}
                >
                  이용약관
                </span>
                <span 
                  onClick={(e) => handleTerms(e)}
                  style={{ cursor: 'pointer' }}
                >
                  개인정보 처리방침
                </span>
              </div>
            )
          }

          <div className="Footer--sns-container">
            <button
              className="Footer--sns-buttons"
              onClick={goToKaKaoTalk}
            >
              <img 
                alt="kakaotalk" 
                src={require("./../../assets/kakaotalk-icon.png")}
                className="Footer--sns-buttons-img"
              />
            </button>
            <button
              className="Footer--sns-buttons"
              onClick={goToInsta}
            >
              <img 
                alt="instagram" 
                src={require("./../../assets/instagram-icon.png")}
                className="Footer--sns-buttons-img"
              />
            </button>
            <button
              className="Footer--sns-buttons"
              onClick={goToYouTube}
            >
              <img 
                alt="youtube" 
                src={require("./../../assets/youtube-icon.png")}
                className="Footer--sns-buttons-img"
              />
            </button>
          </div>

          {
            isMobile && (
              <div className="Footer--agreement">
                <span onClick={(e) => handleTerms(e)}>이용약관</span>
                <span onClick={(e) => handleTerms(e)}>개인정보 처리방침</span>
              </div>
            )
          }
        </div>

        <div className="Footer--rights"> 
          <span>© 2025 DDG. All rights reserved.</span>
        </div>
      </div>
    </div>
  )
}

export default Footer;