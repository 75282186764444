import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import './Archive.css';
import './../../styles/defaultDesign.css';
/* eslint-disable no-unused-vars */
import Card from "../../components/Card/Card";
import Modal from "../../components/ProfileDetail/Modal";
import SearchBar from "../../components/SearchBar/SearchBar";
import FilterOption from "../../components/Filter/FilterOption";
import { getInfo } from "../../service/getService";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import MobileFilterContent from "../../components/Filter/MobileFilterContent";
import debounce from 'lodash.debounce';
import Search from "./Search";
import NoResult from "./NoResult";
import { useAuth } from "../../utils/AuthContext";
import PopUp from "../../components/PopUp/PopUp";
import Toast from "../../components/PopUp/Toast";
import activeFilterButton from '../../assets/archive-filter-button-active.png';
import filterButton from '../../assets/archive-filter-button.png';
import { useMediaQuery } from "react-responsive";
import PCFilter from "../../components/Filter/PCFilter";


function Archive() {
  const [filterOptions, setFilterOptions] = useState([]);
  const [info, setInfo] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [toast, setToast] = useState(false);
  const [disableObserver, setDisableObserver] = useState(false);

  const { isAuthenticated, loading } = useAuth();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // const sentinelRef = useRef(null);

  const initialFilterData = {
    searchingWord: "",
    membership: [],
    campus: [],
    country: "",
    state: "",
    city: "",
    fields: ""
  };

  const [filterData, setFilterData] = useState(initialFilterData);
  const [initialSearchingWord, setInitialSearchingWord] = useState(filterData.searchingWord)

  const handleChange = (e) => {
    let { name, value } = e.target;
    
    setFilterData((prevState) => {
      const currentField = prevState[name];
      if (Array.isArray(currentField)) {
        // 배열인 경우
        return {
          ...prevState,
          [name]: currentField.includes(value)
            ? currentField.filter((item) => item !== value)
            : [...currentField, value],
        };
      } else {
        // 단수 값인 경우
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const resetFilter = () => {
    setFilterData(initialFilterData);
  }

  const contentProps = {
    data: filterData,
    handleChange: handleChange
  }

  /* functions */
  // fetch the archive data from the database
  const fetchArchData = useMemo(
    () => debounce(async (page, filterData) => {
      if (!hasMore) return;

      setIsLoading(true);

      try {
        const size = 9;
        const direction = 'DESC';
        // console.log("filterData before getInfo:", filterData);
        const responseData = await getInfo(
          page, size, direction, filterData.searchingWord,
          filterData.membership, filterData.campus, filterData.country,
          filterData.state, filterData.city, filterData.fields
        );
        // console.log("filterData after getInfo:", filterData);
        // console.log("profiles:", responseData.data.profiles);
        // console.log("response:", responseData.data)

        if (responseData && responseData.isSuccess) {
          const newData = responseData.data.profiles;
          setInfo(prevInfo => [...prevInfo, ...newData]);
          setTotalNumber(responseData.data.totalElements);

          if (newData.length < size) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error('Failed to fetch data. Please try again later: ', error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [hasMore]
  );

  useEffect(() => {
    fetchArchData(page, filterData);
  }, [fetchArchData, page, filterData]);
  
  // useEffect(() => {
  //   const handleScroll = debounce(() => {
  //     if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && !isLoading && hasMore) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   }, 100);
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [isLoading, hasMore]);

  const observerRef = useRef(null);

  const sentinelRef = useCallback(
    (node) => {
      // 기존 observer가 있다면 disconnect
      if (observerRef.current) observerRef.current.disconnect();

      if (node) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            // console.log("Sentinel intersecting:", entries[0].isIntersecting);
            if (
              entries[0].isIntersecting &&
              !isLoading &&
              hasMore &&
              !disableObserver
            ) {
              setPage((prevPage) => prevPage + 1);
            }
          },
          {
            root: null,
            threshold: 0,
            rootMargin: "200px",
          }
        );
        observerRef.current.observe(node);
      }
    },
    [isLoading, hasMore, disableObserver]
  );

  // close the modal
  const closeModal = (e) => {
    if (e.target.className === 'Archive--modal-backdrop') {
      setModal(false);
    }
    e.stopPropagation();
  };

  const openBottomSheet = () => {
    setIsBottomSheetOpen(true);
  };

  const closeBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  const handleFilterChange = () => {
    // console.log("handlefilter change loaded")
    setPage(1); // 새로운 필터가 적용될 때 페이지를 초기화
    setInfo([]); // 기존 데이터를 초기화
    setHasMore(true); // 더 많은 데이터가 있음을 표시
    setIsBottomSheetOpen(false); // 바텀시트 닫음
    window.scrollTo(0, 0);

    setFilterOptions((prevFilterData) => {
      const newFilterOptions = new Set();
    
      Object.values(filterData).forEach((value) => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            if (!prevFilterData.includes(item)) {
              newFilterOptions.add(item);
            }
          });
        } else if (value && !prevFilterData.includes(value)) {
          newFilterOptions.add(value);
        }
      });
    
      // Ensure only relevant values remain, removing old ones
      return prevFilterData.filter(item => 
        Object.values(filterData).flat().includes(item)
      ).concat(Array.from(newFilterOptions));
    });
    setTimeout(() => setDisableObserver(false), 500);
  };

  const handleEnterPress = () => {
    setInitialSearchingWord(filterData.searchingWord);
    setSearch(false);
    setPage(1); // 새로운 필터가 적용될 때 페이지를 초기화
    setInfo([]); // 기존 데이터를 초기화
    setHasMore(true); // 더 많은 데이터가 있음을 표시
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="Archive--popup-backdrop">
        <PopUp purpose={"로그인"} />
      </div>
    );
  }

  // const wrapperStyle = {
  //   justifyContent: (!isBottomSheetOpen && !isMobile) ? 'center' : 'unset'
  // };

  return(
    <>
      <div className={`Archive--wrapper` + ((!isMobile && isBottomSheetOpen) ? ' selected' : '')}>
        {
          isBottomSheetOpen && !isMobile &&
          (
            <PCFilter
              closeBottomSheet={closeBottomSheet}
              contentProps={contentProps}
              onClickFilterOptions={handleFilterChange}
              resetFilter={resetFilter}
              setFilterOptions={setFilterOptions}
            />
          )
        }
        {/* {!isMobile && (
          <div className={`pc-filter-container ${isBottomSheetOpen ? 'active' : ''}`}>
            <PCFilter
              closeBottomSheet={closeBottomSheet}
              contentProps={contentProps}
              onClickFilterOptions={handleFilterChange}
              resetFilter={resetFilter}
              setFilterOptions={setFilterOptions}
            />
          </div>
        )} */}
        <div className="Archive--container">

          {/* Filter button and Search Bar */}
          <div className="Archive--header-container">
            {   
              isMobile ? (
                <button 
                  className={"Archive--filter-button" + (filterOptions.length > 0 ? " active" : '')}
                  onClick={openBottomSheet}
                >
                  <img
                    alt="filter-button"
                    src={filterOptions.length > 0 ? activeFilterButton : filterButton}
                    width="16" height="17"
                  />
                  <span className="Archive--filter-text">
                    필터
                  </span>
                </button>
              ) : (
                !isBottomSheetOpen && (
                  <button 
                    className={"Archive--filter-button" + (filterOptions.length > 0 ? " active" : '')}
                    onClick={openBottomSheet}
                  >
                    <img
                      alt="filter-button"
                      src={filterOptions.length > 0 ? activeFilterButton : filterButton}
                      width="16" height="17"
                    />
                    <span className="Archive--filter-text">
                      필터
                    </span>
                  </button>
                )
              )
            }

            <SearchBar 
              formData={filterData} 
              handleChange={handleChange}
              onEnterPress={handleEnterPress}
            />
          </div>

          {/* Filter Options */}
          <div className="Archive--mid-section-container">
            <div className="Archive--filter-options-wrapper">
              <div className="Archive--filter-options-container">
                {
                  filterOptions.length > 0 && (
                    filterOptions.map((item, index) => (
                      <FilterOption 
                        title={item} 
                        setFilterOptions={setFilterOptions}
                        setFilterData={setFilterData}
                        setPage={setPage}
                        setInfo={setInfo}
                        setHasMore={setHasMore}
                      />
                    ))
                  )
                }
              </div>
            </div>

            {
              (!isLoading && totalNumber > 0) && (
                <span 
                  className="b0-10-m pc-font-14 pc-padding-top-28"
                  style={{ color: "#66707A"}}
                >
                  검색결과&nbsp;
                  {
                    isLoading ? 0 : totalNumber
                  }
                  명
                </span>
              )
            }
          </div>

          {/* Cards */}
          <div className="Archive--cards-container">
            {
              // isLoading ? (
              //   <p>Loading...</p>
              // ) : (
                (filterData.searchingWord.length >= 0 && info.length === 0) ? (
                  <NoResult searchingWord={initialSearchingWord}/>
                ) : (
                  info.map((item, i) => {
                    return (
                      <Card 
                        key={i} 
                        data={item}
                        setModal={setModal}
                        setModalInfo={setModalInfo}
                      />
                    )
                  })
                // )
            )}
          </div>
          <div ref={sentinelRef} style={{ height: "1px" }} />
          { filterData.searchingWord.length === 0 && info.length !== 0 && !hasMore && !isMobile && (
            <div className="Archive--cards-disclaimer">
              <span>더 이상 불러올 프로필이 없습니다.</span>
            </div>
          )}

        </div>
      
        {/* Modal */}
        { modal && (
          <div className="Archive--modal-backdrop" onClick={(e) => closeModal(e)}>
            <Modal 
              info={modalInfo} 
              setModal={setModal}
              setToast={setToast}
            />
          </div>
        )}

        {
          isBottomSheetOpen && isMobile && (
            <BottomSheet
              Content={MobileFilterContent}
              isBottomSheetOpen={isBottomSheetOpen}
              closeBottomSheet={closeBottomSheet}
              contentProps={contentProps}
              onClickFilterOptions={handleFilterChange}
              resetFilter={resetFilter}
              setFilterOptions={setFilterOptions}
            />
          )
        }

        {
          isBottomSheetOpen && !isMobile && (
            <div></div>
          )
        }

        {
          toast && (
            <Toast purpose={"이메일"}/>
          )
        }
      </div>

      {/* {
        search && (
          <Search 
            closeSearch={toggleSearch}
            formData={filterData}
            handleChange={handleChange}
            onEnterPress={handleEnterPress}
          />
        )
      } */}

      
    </>
  )
}

export default Archive;