import React from "react";
import './Modal.css';
import '../../styles/defaultDesign.css';
import { handleCopyClipBoard } from "../../utils/usefulFunctions";

function Modal({info, setModal, setToast}) {
  const closeModal = () => {setModal(false)}

  const membership = () => {
    if (info.alumniType === 0) {
      return "졸업생";
    } else {
      return "인기모";
    }
  }

  const copyEmail = () => {
    handleCopyClipBoard(info.email);
    setToast(true);

    setTimeout(() => {
      setToast(false);
    }, 500);
  }

  const onClickLink = () => {
    console.log(info.sns);
    window.open(info.sns);
  }

  const fields = [info?.field1 ?? '', info?.field2 ?? '', info?.field3 ?? ''].filter(Boolean).join(', ');;
  const location = [info?.city ?? '', info?.country ?? ''].filter(Boolean).join(', ');
  const win_width = window.innerWidth;

  if (win_width >= 768) {
    return (
      <div className="Modal--container">
        <div className="Modal--header">
          <div className="Modal--profile-picture-container">
            <img 
              className="Modal--profile-picture"
              src={info?.profileImageURL || require("./../../assets/profile-pic-11.png")}
              alt="profile-pic"
            />
          </div>
          
          <div className="Modal--profile-header-content">
            <div
              className="Modal--profile-header-link"
              onClick={copyEmail}
            >
              <img
                alt="copy-email"
                src={require('./../../assets/copy-email.png')}
                className="Modal--profile-header-link-button"
              />
              <span>
                Email
              </span>
            </div>
  
            {
              info?.sns.length > 0 && (
                <div 
                  onClick={onClickLink}
                  className="Modal--profile-header-link">
                  <img
                    alt="copy-link"
                    src={require('./../../assets/link.png')}
                    className="Modal--profile-header-link-button"
                  />
                  <span>
                    Link
                  </span>
                </div>
              )
            }
          </div>
        </div>
  
        <div className="Modal--content-container">
          <div className="Modal--close-button-container">
            <button 
              className="Modal--close-button" 
              onClick={closeModal}
            />
          </div>
          
          {/* basic info */}
          <div className="Modal--basic-info">
            <span 
              className="b0-10-m"
              style={{ color: "#66707A"}}
            >
              {info.campus} {info.alumniType === 0 ? `${info.generation}회` : ""} {membership()}
            </span>
  
            <div className="Modal--basic-info narrow-gap">
              <span 
                className="b7-16-sb"
                style={{ fontWeight: "bold"}}
              >
                {info.korName} | {info.engName}
              </span>
  
              <span className="b1-12-m">{info.introduction}</span>
            </div>
  
            <div className="Modal--basic-info narrow-gap margin">
              <span className="b1-12-m">
                {fields}
              </span>
  
              <span className="b1-12-m">
                {location}
              </span>
            </div>
          </div>
  
          {
            (info?.educations.length > 0 || info?.careers.length > 0) && (
              <div className="Modal--divider"/>
            )
          }
          
          {/* additional info */}
          {
            (info?.educations.length > 0 || info?.careers.length > 0) && (
              <div className="Modal--additional-info">
  
                {/* education */}
                { 
                  info?.educations.length > 0 && (
                    <div className="Modal--basic-info">
                      <span  
                        className="Modal--addtional-info-title"
                      >
                        학력
                      </span>
                      <div className="Modal--additional-info-content">
                        {
                          info?.educations.map((item, index) => {
                            return (
                              <div className="Modal--education-content" key={index}>
                                <span className="Modal--additional-info-text">
                                  {item.schoolName} | {item.major}
                                </span>
                                <span 
                                  className="Modal--additional-info-text"
                                  style={{ color: "#66707A" }}
                                >
                                  {item.degree} · {item.status}
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                }
                
                {/* careers */}
                {
                  info?.careers.length > 0 && (
                    <div className="Modal--basic-info">
                      <span  
                        className="Modal--addtional-info-title"
                      >
                        경력/경험
                      </span>
                      <div className="Modal--additional-info-content">
                        {
                          info?.careers.map((item, index) => {
                            return (
                              <div className="Modal--education-content" key={index}>
                                <span
                                  className="Modal--additional-info-text"
                                >
                                  {item.companyName} | {item.position}
                                </span>
                                <span
                                  className="Modal--additional-info-text"
                                  style={{ color: "#66707A" }}
                                >
                                  {item.startYear}년 · {item.duration}
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                      
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    )
  }else{
    return (
      <div className="Modal--container">
        <div className="Modal--header">
  
  
          <div className="Modal--close-button-container">
            <button 
              className="Modal--close-button" 
              onClick={closeModal}
            />
          </div>
  
          <div className="Modal--profile-picture-container">
            <img 
              className="Modal--profile-picture"
              src={info?.profileImageURL || require("./../../assets/profile-pic-11.png")}
              alt="profile-pic"
            />
          </div>
          
          <div className="Modal--profile-header-content">
            <div
              className="Modal--profile-header-link"
              onClick={copyEmail}
            >
              <img
                alt="copy-email"
                src={require('./../../assets/copy-email.png')}
                className="Modal--profile-header-link-button"
              />
              <span>
                Email
              </span>
            </div>
  
            {
              info?.sns.length > 0 && (
                <div 
                  onClick={onClickLink}
                  className="Modal--profile-header-link">
                  <img
                    alt="copy-link"
                    src={require('./../../assets/link.png')}
                    className="Modal--profile-header-link-button"
                  />
                  <span>
                    Link
                  </span>
                </div>
              )
            }
          </div>
        </div>
  
        <div className="Modal--content-container">
  
          {/* basic info */}
          <div className="Modal--basic-info">
            <span 
              className="b0-10-m"
              style={{ color: "#66707A"}}
            >
              {info.campus} {info.alumniType === 0 ? `${info.generation}회` : ""} {membership()}
            </span>
  
            <div className="Modal--basic-info narrow-gap">
              <span 
                className="b7-16-sb"
                style={{ fontWeight: "bold"}}
              >
                {info.korName} | {info.engName}
              </span>
  
              <span className="b1-12-m">{info.introduction}</span>
            </div>
  
            <div className="Modal--basic-info narrow-gap margin">
              <span className="b1-12-m">
                {fields}
              </span>
  
              <span className="b1-12-m">
                {location}
              </span>
            </div>
          </div>
  
          {
            (info?.educations.length > 0 || info?.careers.length > 0) && (
              <div className="Modal--divider"/>
            )
          }
          
          {/* additional info */}
          {
            (info?.educations.length > 0 || info?.careers.length > 0) && (
              <div className="Modal--additional-info">
  
                {/* education */}
                { 
                  info?.educations.length > 0 && (
                    <div className="Modal--basic-info">
                      <span  
                        className="Modal--addtional-info-title"
                      >
                        학력
                      </span>
                      <div className="Modal--additional-info-content">
                        {
                          info?.educations.map((item, index) => {
                            return (
                              <div className="Modal--education-content" key={index}>
                                <span className="Modal--additional-info-text">
                                  {item.schoolName} | {item.major}
                                </span>
                                <span 
                                  className="Modal--additional-info-text"
                                  style={{ color: "#66707A" }}
                                >
                                  {item.degree} · {item.status}
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                }
                
                {/* careers */}
                {
                  info?.careers.length > 0 && (
                    <div className="Modal--basic-info">
                      <span  
                        className="Modal--addtional-info-title"
                      >
                        경력/경험
                      </span>
                      <div className="Modal--additional-info-content">
                        {
                          info?.careers.map((item, index) => {
                            return (
                              <div className="Modal--education-content" key={index}>
                                <span
                                  className="Modal--additional-info-text"
                                >
                                  {item.companyName} | {item.position}
                                </span>
                                <span
                                  className="Modal--additional-info-text"
                                  style={{ color: "#66707A" }}
                                >
                                  {item.startYear}년 · {item.duration}
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                      
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default Modal;