import axios from "axios";
const API_URL = 'https://gvzip.com';

export const register = async (formData, profileImage) => {
  try {
    const data = new FormData();
    const json = JSON.stringify(formData);

    const blob = new Blob([json], { type: 'application/json' });
    data.append('signUpRequest', blob);

    // console.log("profileImage: ", profileImage);

    if (profileImage !== null) {
      data.append('profileImage', profileImage);
    } else {
      // console.log("no profile image");
      const imageName = "profile-pic-9.png";
      const imageUrl = require(`./../assets/profile-pic-9.png`);

      // 기본 이미지 파일을 가져오는 비동기 함수
      const fetchDefaultImage = async () => {
        const res = await fetch(imageUrl);
        const blob = await res.blob();
        const file = new File([blob], imageName, { type: 'image/*' });
        // console.log("file created!");
        data.append('profileImage', file);
      };

      // 기본 이미지 추가
      await fetchDefaultImage();
    }
    
    // FormData 확인
    // for (let [key, value] of data.entries()) {
    //   console.log(key, value);
    // }

    const csrfToken = sessionStorage.getItem('csrf_token');
    const response = await axios.post(`${API_URL}/signup`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "X-CSRF-TOKEN": csrfToken
      },
      withCredentials: true
    });

    if (response.data.isSuccess) {
      return response.data;
    }
  } catch (error) {
    console.error("Error occurred while registering the user!");
    console.error(error);
  }
};

export const logoutUser = async () => {
  try {
    const csrfToken = sessionStorage.getItem('csrf_token');

    if (!csrfToken) {
      console.error("CSRF token is missing!");
      return;
    }

    // Create a form dynamically
    const form = document.createElement("form");
    form.method = "POST";
    form.action = `${API_URL}/logout`; // Server logout endpoint

    // CSRF Token as a hidden input
    const csrfInput = document.createElement("input");
    csrfInput.type = "hidden";
    csrfInput.name = "_csrf"; // Use the correct field name your backend expects
    csrfInput.value = csrfToken;
    form.appendChild(csrfInput);

    // Append the form to the document and submit
    document.body.appendChild(form);
    form.submit();
  } catch (error) {
    console.error("Error occurred while logging out the user!");
    console.error(error);
  }
};