import React from "react";
import AlumniFirstPage from "./AlumniFirstPage";
import AlumniSecondPage from "./AlumniSecondPage";
import AlumniThirdPage from "./AlumniThirdPage";
import AlumniFourthPage from "./AlumniFourthPage";
import Education from "../Education";
import Career from "../Career";
import Privacy from "../Agreement/Privacy";
import TermsOfUse from "../Agreement/TermsOfUse";
import EditEducation from "../EditEducation";
import EditCareer from "../EditCareer";
import ProvisionOfPrivacy from "../Agreement/ProvisionOfPrivacy";

function AlumniForm({formData, handleChange, handleBornYearChange, handleBornMonthChange, handleBornDayChange, handleEmail, isValidEmail, registerUser, profileImage, previewImage, handleProfileImage, currentPage, goToNextPage, goToPreviousPage, handleEducationClick, handleArrayData, handleCareerClick, handleTermClick, handleTermOfUseClick, handleProvisionOfPrivacy, isValidYear, isValidMonth, isValidDay, educationNumber, closeEditEducation, setEducationNumber, openEditEducation, careerNumber, setCareerNumber, openEditCareer, handleBackButton, emailTouched}) {
  return(
    <>
      { currentPage === 1 && (
          <AlumniFirstPage 
            formData={formData}
            handleChange={handleChange}
            handleBornYearChange={handleBornYearChange}
            handleBornMonthChange={handleBornMonthChange}
            handleBornDayChange={handleBornDayChange}
            handleEmail={handleEmail}
            isValidEmail={isValidEmail}
            goToNextPage={goToNextPage}
            isValidYear={isValidYear}
            isValidMonth={isValidMonth}
            isValidDay={isValidDay}
            emailTouched={emailTouched}
          />
        )
      }

      {
        currentPage === 2 && (
          <AlumniSecondPage 
            formData={formData}
            handleChange={handleChange}
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            handleEducationClick={handleEducationClick}
            handleArrayData={handleArrayData}
            handleCareerClick={handleCareerClick}
            setEducationNumber={setEducationNumber} 
            educationNumber={educationNumber}
            openEditEducation={openEditEducation}
            careerNumber={careerNumber}
            setCareerNumber={setCareerNumber}
            openEditCareer={openEditCareer}
          />
        )
      }
      
      {
        currentPage === 3 && (
          <AlumniThirdPage 
            formData={formData}
            handleChange={handleChange}
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
          />
        )
      }
      
      {
        currentPage === 4 && (
          <AlumniFourthPage 
            formData={formData}
            handleChange={handleChange}
            goToPreviousPage={goToPreviousPage}
            registerUser={registerUser}
            profileImage={profileImage}
            previewImage={previewImage}
            handleProfileImage={handleProfileImage}
            handleArrayData={handleArrayData}
            handleTermClick={handleTermClick}
            handleTermOfUseClick={handleTermOfUseClick}
            handleProvisionOfPrivacy={handleProvisionOfPrivacy}
          />
        )
      }

      {
        currentPage === 5 && (
          <Education
            handleArrayData={handleArrayData}
            handleBackButton={handleBackButton}
          />
        )
      }

      {
        currentPage === 6 && (
          <Career
            handleArrayData={handleArrayData}
            handleBackButton={handleBackButton}
          />
        )
      }

      {
        currentPage === 7 && (
          <Privacy
            handleBackButton={handleBackButton}
          />
        )
      }

      {
        currentPage === 8 && (
          <TermsOfUse 
            handleBackButton={handleBackButton}
          />
        )
      }
      
      {
        currentPage === 9 && (
          <EditEducation
            handleChange={handleChange}
            index={educationNumber}
            closeEditEducation={closeEditEducation}
            handleBackButton={handleBackButton}
          />
        )
      }
      {
        currentPage === 10 && (
          <EditCareer
            handleChange={handleChange}
            index={careerNumber}
            closeEditEducation={closeEditEducation}
            handleBackButton={handleBackButton}
          />
        )
      }
      {
        currentPage === 11 && (
          <ProvisionOfPrivacy
            handleBackButton={handleBackButton}
          />
        )
      }
    </>
  )
}

export default AlumniForm;