import React from "react";
import '../ProfileInfo.css';
import '../../../../styles/defaultDesign.css';
import Name from "../../../../components/SignUpComponents/Name";
import DateOfBirth from "../../../../components/SignUpComponents/DateOfBirth";
import Gender from "../../../../components/SignUpComponents/Gender";
import Email from "../../../../components/SignUpComponents/Email";
import ButtonSelection from "../../../../components/SignUpComponents/ButtonSelection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { reset, resetCareer, clearUserInfo } from "../../../../redux/store";

function ParentFirstPage({formData, handleChange, handleBornYearChange, handleBornMonthChange, handleBornDayChange, handleEmail, isValidEmail, goToNextPage, emailTouched}) {

  const campusList = ['음성', '문경', '미국'];
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const educations = useSelector((state) => state.educations.educations);
  const careers = useSelector((state) => state.careers.careers);

  useEffect(() => {
    if (userInfo === null && educations.length === 0 && careers.length === 0) {
      dispatch(reset());
      dispatch(resetCareer());
      dispatch(clearUserInfo());
      // console.log("reset!")
    }
  }, [userInfo, dispatch, educations, careers]);

  return (
    <div className="Profile--content-container-SignUp huge-gap">
      <span className="SignUp-PC-title">회원가입</span>
      {/* Name */}
      <Name 
        formData={formData}
        handleChange={handleChange}
      />

      {/* DoB */}
      <DateOfBirth 
        formData={formData}
        handleBornYearChange={handleBornYearChange}
        handleBornMonthChange={handleBornMonthChange}
        handleBornDayChange={handleBornDayChange}
      />

      {/* Gender */}
      <Gender 
        formData={formData}
        handleChange={handleChange}
      />

      {/* Email */}
      <Email
        formData={formData}
        handleEmail={handleEmail}
        isValidEmail={isValidEmail}
        emailTouched={emailTouched}
      />

      {/* Campus */}
      <ButtonSelection 
        formData={formData}
        handleChange={handleChange}
        title={"자녀 캠퍼스"}
        name={"campus"}
        list={campusList}
        isMandatory={true}
      />

      <button 
        className="Profile--navigate-button basic-info-next-btn"
        // disabled={!isFirstDone}
        onClick={goToNextPage}
        // style={{ marginBottom: "44px"}}
      >
        <span className="h2-18-sb">다음</span>
      </button>
    </div>
  )
}

export default ParentFirstPage;