import React from "react";
import './Agreement.css';
import './../../../../styles/defaultDesign.css';

function TermsOfUse({handleBackButton, isMobile}){
  return (
    <div className="TermsOfUse--wrapper">
      <div className="TermsOfUse--container">

        {/* <span className="TermsOfUse-title">이용약관</span>

        <div className="TOU--close-button-container">
          <button 
            className="TOU--close-button" 
            onClick={handleBackButton}
          />
        </div> */}

        <div className="TermsOfUse--header">
          <span className="TermsOfUse-title">이용약관</span>
          <button 
            className={`TermsOfUse-close-button${isMobile ? '-disable' : ''}`}
            onClick={() => handleBackButton()}
          />
        </div>

        <div className="Privacy--title-mobile">시행일 : 2024년 7월 5일</div>

        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">계약</span>
          <span className="b6-16-m">
            1.1 GVZIP 서비스를 이용하려면 이러한 모든 약관에 동의해야 합니다. 이 약관(계약 또는 이용약관으로 부름)에 동의하지 않는 경우, 가입하기 등 GVZIP 서비스에 접속하거나 서비스를 이용할 수 없습니다. 이 계약을 종료하고 싶을 경우, 언제든지 계정을 폐쇄하고 GVZIP 서비스 접속 또는 사용을 중단하면 됩니다. 
            <br />
            <br />
            1.2 회원과 방문자 <br />
            GVZIP 서비스에 가입하면 회원이 됩니다. GVZIP 서비스에 가입하지 않을 경우 방문자로서 Home Page를 둘러볼 수 있습니다.
            <br />
            <br />
            1.3 개정 <br />
            GVZIP은 필요에 따라 이 문서, 개인정보 처리방침을 개정합니다. 내용이 개정될 경우, 이를 GVZIP 서비스 등을 통해서 법적고지하여 회원들이 개정 사항이 시행되기 전에 개정 사항을 검토할 수 있도록 할 것입니다. GVZIP은 해당 개정 사항이 소급 적용되지 않음에 동의합니다. 개정 사항에 반대할 경우 계정을 폐쇄하시기 바랍니다. 약관 개정에 대한 고지가 게시 또는 발송된 후에도 사용자가 계속해서 GVZIP 서비스를 사용하는 경우, 사용자가 시행일부터 개정된 약관에 동의함을 의미합니다.
          </span>
        </div>

        <br />
        <br />

        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">의무</span>

          <span className="b6-16-m">
            2.1 서비스 적격성 <br />
            다음은 사용자의 의무입니다. 이 약관의 대상이 되려면 사용자는 GVZIP이 정한 최소 연령보다 높아야 합니다. 사용자는 비밀번호에 대한 기밀을 유지합니다. 본인이 아닌 다른 사람과 계정을 공유하지 않고, GVZIP의  규정과 법을 따라야 합니다. 사용자는 약속된 보안의 의무 안에서 정보를 저장할 것에 동의해야 합니다. 사용자는 GVZIP 웹사이트를 통해서 공지사항을 받는 데 동의합니다. 연락처 정보가 최신 정보가 아닌 경우 이러한 공지사항을 받지 못하게 될 수 있습니다. 사용자가 GVZIP 서비스에서 정보를 공유할 경우 다른 사람들도 해당 정보를 조회, 복사, 사용할 수 있습니다.
            14세 미만은 본 서비스를 사용할 수 없습니다.
            서비스를 사용하려면 사용자는 (1) 위에 명시된 최소 연령보다 높아야 하고, (2) GVZIP 계정을 1개만 보유해야 하며, 반드시 실명을 사용해야 하고, (3) GVZIP 서비스 이용을 사용할 수 없도록 차단된 적이 없어야 합니다. 다른 사람이나 14세 미만의 미성년자를 대신해 등록된 계정 등 허위 정보를 이용해 계정을 만드는 것은 약관에 위배되는 행위입니다.
            최소 연령은 14세입니다. 그러나 부모의 동의 없이 합법적으로 GVZIP 서비스를 제공 받기 위해서 특정 연령이 되어야 할 경우, 해당 연령이 최소 연령이 됩니다.
            <br />
            <br />
            2.2 계정 <br />
            회원이란 계정을 보유한 사람입니다. 사용자는 (1) 강력한 비밀번호를 사용하고 비밀로 유지하며, (2) 계정 정보를 일절 양도하지 않으며 (3) 법과 GVZIP 준수사항 및 금지사항을 따라야 합니다. 사용자는 사용자의 계정을 해지하거나 타인이 사용했다고 신고하지 않는 한, 사용자의 계정을 통해 발생하는 모든 일에 책임을 집니다.
            <br />
            <br />
            2.3 공지 및 메일 <br />
            사용자는 GVZIP의 공지사항 및 메일이 (1) 서비스 내에서 또는 (2) 회원이 입력한 연락처(이메일, 전화번호 등)로 제공된다는 점에 동의합니다. 사용자는 본인의 연락처를 최신 정보로 유지할 것에 동의합니다.
            주요 용어
            최소 연령
            최소 연령 미만인 회원은 서비스를 계속 사용할 수 없습니다.
          </span>
        </div>
        
        <br />
        <br />

        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">권리 및 제한</span>

          <span className="b6-16-m">
            3.1. GVZIP에 부여하는 사용권 <br />
            사용자는 GVZIP 아카이브에 제공하는 콘텐츠, 의견, 개인 정보를 모두 소유하나, 이에 대한 비독점적 사용권을 GVZIP에 부여합니다. 회원과 GVZIP은 콘텐츠에 개인 데이터가 포함될 경우, 이는 GVZIP 개인정보 처리방침을 따른다는 점에 동의합니다. 사용자는 사용자가 공유할 권리를 지닌 정보 및 콘텐츠만을 제공하고 사용자의 GVZIP 프로필에 사실만을 기입할 것을 약속합니다. GVZIP은 향후 모든 서비스를 변경 또는 종료할 수 있습니다. 다른 사람이 GVZIP에 게시한 정보와 내용을 사용하여 발생한 책임은 사용자에게 있습니다. GVZIP은 지적재산권에 대해 사용자에게 고지합니다. 회원과 GVZIP은 GVZIP이 개인정보 처리방침의 내용 및 회원 선택(설정 등)에 의거해서 회원이 제공한 모든 정보와 개인 데이터를 조회, 저장, 처리, 사용할 수 있다는 점에 동의합니다. 사용자가 GVZIP 서비스에 관한 의견이나 건의 사항을 제공할 경우, GVZIP이 보상 없이 특정한 목적으로 해당 사항을 사용 및 공유할 수 있다는 점에 동의합니다. 사용자는 법, 특정인의 권리(지적재산권 등)에 저촉되지 않는 콘텐츠 및 정보만을 제공해야 합니다. 또한 사용자는 자신의 프로필에 사실만을 기재해야 합니다. GVZIP은 법, 혹은 팀의 판단 하에 의해 부적격한 정보를 삭제해야 할 수도 있습니다.
            <br />
            <br />
            3.2. 서비스 정보의 노출 <br />
            서비스를 사용함으로써 사용자는 부정확하거나, 불완전하거나, 최신 정보가 아니거나, 사실을 오도하거나, 불법적이거나, 불쾌하거나, 해로운 정보에 노출될 수 있습니다. GVZIP은 일반적으로 회원들이 제공하는 모든 정보를 검토하지는 않습니다. GVZIP 서비스가 오용되는 경우를 항상 예방할 수 있지 않으므로, 사용자는 GVZIP이 이러한 오용을 책임지지 않는다는 점에 동의합니다. 사용자는 GVZIP 사용시 본인이 서비스에 입력한 정보의 유출과 연관될 수 있는 위험이 있음을 인정합니다. 사용자는 이에 대해 회원가입 시 거부할 선택권을 갖습니다.
            <br />
            <br />
            3.3. 제한 <br />
            GVZIP은 사용자의 서비스 이용을 제한할 권리를 지닙니다. 사용자가 약관 또는 법을 위반 또는 서비스를 오용(예: 준수사항 및 금지사항  위반, 개인정보 유출)하는 경우 GVZIP은 사용자의 계정을 제한, 중지 또는 종료할 권리를 지닙니다.
            <br />
            <br />
            3.5. 지적재산권 <br />
            서비스에 관한 모든 지적재산권은 GVZIP에서 보유합니다. 서비스와 연계되어 사용되는 상표 및 로고는 각 소유주의 상표일 수 있습니다.
          </span>
        </div>

        <br /><br />

        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">면책 및 책임 제한</span>

          <span className="b6-16-m">
            4.1. 무보장 <br />
            GVZIP은 서비스 품질, 안전, 신뢰도에 대한 일체의 법적 의무를 지지 않습니다. GVZIP은 서비스 중단이나 오류 없음에 대한 인정 등 서비스에 대한 인정이나 보장을 하지 않으며 서비스를 “있는 그대로” 그리고 “이용 가능한 대로” 제공합니다.
            <br />
            <br />
            4.2. 책임 제외 <br />
            법이 허용하는 최대 범위까지 수익 손실 또는 사업 기회 손실, 명예 데이터 손실 또는 간접적, 부수적, 결과적 특수적 또는 징벌적 손해에 대해 이 약관과 관련해 책임을 지지 않습니다.
          </span>
        </div>
        <br /><br />
        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">준거법과 분쟁 해결</span>

          <span className="b6-16-m">
            5.1. 사용자와 GVZIP 사이에 법적 분쟁이 진행되는 드문 경우, 사용자의 거주 지역에 따라 사용자와 GVZIP은 대한민국 법률에 따라, 또는 사용자의 지역 법원에서 현지 법률에 따라 분쟁을 해결하는 데 동의합니다. 대한민국의 법률은 GVZIP의 서비스 제공과 관련된 모든 소송의 준거법이 되지만, 이는 GVZIP이 사용자가 상주하는 지역에서 서비스를 제공하는 국가의 법률에 따른 의무적인 소비자 보호 권리를 사용자로부터 박탈하지 않습니다. 준거법과 관련하여, 사용자와 GVZIP은 본 사용자약관으로 인해 또는 본 사용자약관과 관련해 발생하는 모든 분쟁에 대해 사용자가 상주하는 지역에서 서비스를 제공하는 국가의 법원을 선택하거나 대안으로 대한민국의 해당 법원을 선택할 수 있습니다. 사용자와 대한민국의 법이 상충될 경우를 제외하고 이 약관 및/또는 서비스에 관한 분쟁 시 대한민국 헌법만이 준거법이 된다는 것에 동의합니다. GVZIP과 사용자 모두 모든 소송과 분쟁에 대한 법적 절차가 대한민국에 있는 주법정에서만 이루어질 수 있음에 동의하고, 본 법정의 개인 관할권에 속한다는 데 각각 동의합니다.
          </span>
        </div>
        <br /> <br />
        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">GVZIP 준수사항 및 금지사항</span>

          <span className="b6-16-m">
          6.1. 준수 사항 <br />
          개인정보법, 지적재산권법, 스팸방지법 등 모든 관련법을 준수해야 합니다. 그리스도인 다운 방식으로 서비스 사용을 권장합니다.
          <br />
          <br />
          6.2. 금지 사항 <br />
           - 신분 위조, 신분 허위 진술, 자신(실제 존재하는 사람)이 아닌 누군가의 회원 프로필을 생성하는 행위, 다른 사람의 계정을 사용하거나 사용하려고 시도하는 행위
           - GVZIP에서 서비스를 스크레이핑하거나, 프로필 및 기타 데이터 등을 복제하기 위한 소프트웨어, 기기, 스크립트, 로봇, 기타 방법 또는 프로세스 등을 개발, 지원, 사용하는 행위(크롤러, 브라우저 플러그인, 애드온, 기타 기술 등)
           - 보안 기능 우회 또는 조회 권한 또는 서비스 사용을 우회하는 행위
           - GVZIP의 동의 없이 검색 엔진 등 타사를 직간접적으로 통해서 GVZIP 서비스에서 획득한 정보를 복제, 사용, 공개, 배포하는 행위
           - 공개할 권리가 없는 정보(타인(사용자의 고용주 포함)의 기밀 정보 등)를 공개하는 행위
           - 저작권, 특허, 상표, 영업기밀, 저작권, 기타 소유권 등 타인의 지적 재산권을 침해하는 행위.
           - (1)오픈소스 라이선스 하에서 출시된 경우를 제외하고 GVZIP 기술을 복제 또는 배포 (2) GVZIP이라는 단어, GVZIP 로고를 특정 사업명, 이메일, URL을 사용하는 행위 등 GVZIP의 지적재산권이나 권리를 침해하는 행위
           - 소프트웨어 바이러스, 웜 또는 기타 악성 코드를 담은 콘텐츠를 게시하는 행위
           - 오픈소스가 아닌 서비스나 특정 관련 기술의 소스코드를 빼내기 위해 역엔지니어링, 역파일, 역어셈블, 암호화, 기타 방법을 수행하는 행위
           - GVZIP의 명시적 동의 없이 GVZIP과 제휴를 맺었다거나 GVZIP의 보증을 받았다는 것을 시사 또는 진술하는 행위
           - 서비스 또는 관련 데이터의 이용권을 임대, 대여, 대출, 거래, 판매/재판매 또는 영리화거나 GVZIP동의 없이 이용하는 행위
           - GVZIP 서비스에서 본인의 프로필이나 그룹을 홍보할 목적을 제외하고, GVZIP 동의 없이 GVZIP 서비스에 딥링크를 수행하는 행위
           - 본 서비스에 접속하거나, 연락처를 추가 또는 다운로드하거나, 메일을 전송 또는 재전송하기 위해 봇 또는 기타 자동화 방법을 사용하는 행위
           - 경쟁을 목적으로 본 서비스의 이용가능성, 성능 또는 기능을 모니터링하는 행위
           - 본 서비스의 운영을 방해하거나 불합리한 수준의 과부하가 걸리게 하는 행위
          </span>
        </div>
        <br /><br />
        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">콘텐츠 관련 이의 제기</span>

          <span className="b6-16-m">
            7.1. GVZIP은 모든 사람의 지적재산권을 존중합니다. 회원이 게시한 정보는 정확하고 타인의 지적 재산권 또는 기타 권리를 침해하지 않아야 합니다. 당사는 회원이 게시한 콘텐츠에 관한 신고를 처리하기 위한 정책과 절차를 제공합니다.
          </span>
        </div>
        <br /><br />

        <div className="TermsOfUse--content">
          <span className="Privacy--title-mobile">GVZIP 연락처</span>

          <span className="b6-16-m">
            8.1. GVZIP에 법적 공지 및 일반 문의는 GVZIP 이메일(team8ddg@gmaill.com) 또는 GVZIP 인스타그램(@teamddg) 다이렉트 메시지로 하시면 됩니다.
          </span>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse;