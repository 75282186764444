import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import './BottomSheet.css';
import '../../pages/authenticate/SignUp/ProfileInfo.css';

function BottomSheet({ Content, isBottomSheetOpen, closeBottomSheet, contentProps, onClickFilterOptions, resetFilter, setFilterOptions }) {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  // const isMobile = viewportWidth < 768;
  const isMobile = viewportWidth > 0;
  const openPosition = isMobile ? viewportHeight * 1.05 : viewportWidth * 0.194; // 88% for mobile, 81% for desktop
  const [{ x, y }, set] = useSpring(() => ({
    x: isMobile ? 0 : -openPosition,
    y: isMobile ? viewportHeight : 0 
  }));
  const bottomSheetRef = useRef(null);
  const maxDrag = -5; // Dragging limit
  const dragThreshold = 10; // Minimum distance to register a drag

  useEffect(() => {
    const updateViewportDimensions = () => {
      const vh = window.innerHeight;
      const vw = window.innerWidth;
      setViewportHeight(vh);
      setViewportWidth(vw);
      if (isBottomSheetOpen) {
        set({
          x: isMobile ? 0 : 0,
          y: isMobile ? vh * 0.12 : 0,
        });
      } else {
        set({
          x: isMobile ? 0 : -openPosition,
          y: isMobile ? vh : 0,
        });
      }
    };

    window.addEventListener('resize', updateViewportDimensions);
    updateViewportDimensions(); // Initial setup

    return () => window.removeEventListener('resize', updateViewportDimensions);
  }, [isBottomSheetOpen, set, isMobile, openPosition]);

  const bind = useDrag(({ down, movement: [mx, my], memo = { x: x.get(), y: y.get() } }) => {
    if (isMobile) {
      if (down) {
        const newY = Math.max(my + memo.y, maxDrag);
        set({ y: newY });
      } else {
        if (my > viewportHeight * 0.5) {
          closeBottomSheet();
        } else {
          set({ y: viewportHeight * 0.12 });
        }
      }
    }
    return memo;
  }, { from: () => ({ x: x.get(), y: y.get() }), threshold: dragThreshold });

  return (
    <>
      {isBottomSheetOpen && <div className="bottom-sheet-overlay" onClick={closeBottomSheet} />}
      <animated.div
        className={`bottom-sheet ${isBottomSheetOpen ? 'open' : ''}`}
        style={{
          transform: isMobile
            ? y.to(y => `translateY(${y}px)`)
            : x.to(x => `translateX(${x}px)`),
          height: isMobile ? openPosition : '100%',
          width: isMobile ? '100%' : openPosition,
        }}
        {...bind()}
        ref={bottomSheetRef}
      >
        <div className="bottom-sheet-handle"></div>
        <div className="Profile--content-container">
          <Content 
            contentProps={contentProps} 
            onClickFilterOptions={onClickFilterOptions}
            resetFilter={resetFilter}
            setFilterOptions={setFilterOptions}
          />
        </div>
      </animated.div>
    </>
  );
}

export default BottomSheet;
