import React, { useEffect, useState } from "react";
import './../Signup.css';
import './../ProfileInfo.css';
import './../../../../styles/defaultDesign.css';
import PictureUploader from "../../../../components/PictureUploader/PictureUploader";
import Terms from "../../../../components/Terms/Terms";
import './../../../../components/BottomSheet/BottomSheet.css';

function AlumniFourthPage({ formData, handleChange, goToPreviousPage, registerUser, profileImage, previewImage, handleProfileImage, handleArrayData, handleTermClick, handleTermOfUseClick, handleProvisionOfPrivacy }) {

  const [termOfUse, setTermOfUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [providePrivacy, setProvidePrivacy] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [clickCount, setClickCount] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const agreeAll = () => {
    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      const isOddClick = newCount % 2 !== 0;

      setTermOfUse(isOddClick);
      setPrivacy(isOddClick);
      setProvidePrivacy(isOddClick);

      console.log(isOddClick, isOddClick, isOddClick);

      return newCount;
    });
  }

  const toggleTermOfUse = () => {
    setTermOfUse(!termOfUse);
  }
  const togglePrivacy = () => {
    setPrivacy(!privacy);
  }
  const toggleOptionalPrivacy = () => {
    setProvidePrivacy(!providePrivacy);
  }

  useEffect(() => {
    setIsValid(termOfUse && privacy && providePrivacy);
  }, [termOfUse, privacy, providePrivacy])

  return(
    <div 
      className="Profile--content-container-SignUp huge-gap"
    >
      <span className="SignUp-PC-title">회원가입</span>

      {/* Picture */}
      <div className="Profile--content-section wide-gap">
        <div>
          <span className="b7-16-sb-18" style={{ color: "#66707A"}}>사진 (선택) </span>
        </div>

        <PictureUploader 
          picFile={previewImage} 
          onChangePicture={handleProfileImage}
        />
      </div>


      <div className="Profile--content-section wide-gap">
        <div>
          <span className="b7-16-sb-18" style={{ color: "#66707A"}}>링크 (선택) </span>
        </div>

        <input 
          className="Profile--text-input-box"
          type="text" 
          placeholder={"나를 소개하는 링크 입력"}
          name={"sns"}
          defaultValue={formData['sns']}
          onChange={handleChange}
        />
      </div>

      {/* Terms and Agreement */}
      <Terms
        handleTermClick={handleTermClick}
        handleTermOfUseClick={handleTermOfUseClick}
        handleProvisionOfPrivacy={handleProvisionOfPrivacy}
        agreeAll={agreeAll}
        setTermsOfUse={toggleTermOfUse}
        setPrivacy={togglePrivacy}
        setOptionalPrivacy={toggleOptionalPrivacy}
        termOfUse={termOfUse}
        privacy={privacy}
        optionalPrivacy={providePrivacy}
      />

      {/* Navigate Button */}
      <div className="Profile--button-container navigate">
        <button 
          className="Profile--navigate-button prev basic-info-next-btn"
          onClick={goToPreviousPage}
        >
          <span className="h2-18-sb">이전</span>
        </button>
        <button 
          className="Profile--navigate-button basic-info-next-btn"
          onClick={registerUser}
          disabled={!isValid}
        >
          <span className="h2-18-sb">완료</span>
        </button>
      </div>
    </div>
  )
}

export default AlumniFourthPage;