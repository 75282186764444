import React, { useEffect, useState } from "react";
import '../ProfileInfo.css';
import '../../../../styles/defaultDesign.css';
import Name from "../../../../components/SignUpComponents/Name";
import DateOfBirth from "../../../../components/SignUpComponents/DateOfBirth";
import Gender from "../../../../components/SignUpComponents/Gender";
import Email from "../../../../components/SignUpComponents/Email";
import ButtonSelection from "../../../../components/SignUpComponents/ButtonSelection";
import Year from "../../../../components/SignUpComponents/Year";
import Terms from "../../../../components/Terms/Terms";
import ProvisionOfPrivacy from "../Agreement/ProvisionOfPrivacy";
import Privacy from "../Agreement/Privacy";
import TermsOfUse from "../Agreement/TermsOfUse";
import { useDispatch, useSelector } from "react-redux";
import { reset, resetCareer, clearUserInfo } from "../../../../redux/store";

function StudentForm({formData, handleChange, handleBornYearChange, handleBornMonthChange, handleBornDayChange, handleEmail, isValidEmail, registerUser, handleTermClick, handleTermOfUseClick, handleProvisionOfPrivacy, isValidYear, isValidMonth, isValidDay, handleBackButton, currentPage, setCurrentPage, emailTouched}) {
  const campusList = ['음성', '문경', '미국'];
  const [isValid, setIsValid] = useState(false);
  const [termOfUse, setTermOfUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [providePrivacy, setProvidePrivacy] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [clickCount, setClickCount] = useState(0);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const educations = useSelector((state) => state.educations.educations);
  const careers = useSelector((state) => state.careers.careers);

  useEffect(() => {
    if (userInfo === null && educations.length === 0 && careers.length === 0) {
      dispatch(reset());
      dispatch(resetCareer());
      dispatch(clearUserInfo());
      // console.log("reset!")
    }
  }, [userInfo, dispatch, educations, careers]);

  useEffect(() => {
    setCurrentPage(4);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const agreeAll = () => {
    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      const isOddClick = newCount % 2 !== 0;

      setTermOfUse(isOddClick);
      setPrivacy(isOddClick);
      setProvidePrivacy(isOddClick);

      return newCount;
    });
  }

  const toggleTermOfUse = () => {
    setTermOfUse(!termOfUse);
  }
  const togglePrivacy = () => {
    setPrivacy(!privacy);
  }
  const toggleOptionalPrivacy = () => {
    setProvidePrivacy(!providePrivacy);
  }

  useEffect(() => {
    setIsValid(
      formData["korName"].length > 0 &&
      formData["engName"].length > 0 &&
      isValidYear && isValidMonth && isValidDay &&
      formData["sex"].length > 0 &&
      isValidEmail &&
      formData["campus"].length > 0 &&
      String(formData["entranceYear"]).length > 0 &&
      String(formData["expectedGraduationYear"]).length > 0 &&
      termOfUse && privacy && providePrivacy
    )
    // console.log(formData["korName"].length > 0,
    //   formData["engName"].length > 0,
    //   isValidYear, isValidMonth, isValidDay,
    //   formData["sex"].length > 0,
    //   isValidEmail,
    //   formData["campus"].length > 0,
    //   String(formData["entranceYear"]).length > 0,
    //   String(formData["expectedGraduationYear"]).length > 0,
    //   termOfUse, privacy, providePrivacy)
  }, [formData, isValidEmail, isValidYear, isValidMonth, isValidDay, termOfUse, privacy, providePrivacy]);

  // List of entrance year
  const generateYearOptions = () => {
    const yearOptions = [];
    for (let year = 2003; year <= 2025; year++) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return yearOptions;
  };

  // List of grad year
  const generateGradYearOptions = () => {
    const yearOptions = [];
    for (let year = 2003; year <= 2040; year++) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return yearOptions;
  };
  
  return (
    <>
      {
        currentPage === 4 && (
        <div className="Profile--content-container-SignUp huge-gap">
          {/* Name */}
          <Name 
            formData={formData}
            handleChange={handleChange}
          />

          {/* DoB */}
          <DateOfBirth 
            formData={formData}
            handleBornYearChange={handleBornYearChange}
            handleBornMonthChange={handleBornMonthChange}
            handleBornDayChange={handleBornDayChange}
          />

          {/* Gender */}
          <Gender 
            formData={formData}
            handleChange={handleChange}
          />

          {/* Email */}
          <Email
            formData={formData}
            handleEmail={handleEmail}
            isValidEmail={isValidEmail}
            emailTouched={emailTouched}
          />

          {/* Campus */}
          <ButtonSelection 
            formData={formData}
            handleChange={handleChange}
            title={"재학 캠퍼스"}
            name={"campus"}
            list={campusList}
            isMandatory={true}
          />

          {/* Entrance year */}
          <Year 
            formData={formData}
            handleChange={handleChange}
            options={generateYearOptions}
            title={"입학년도"}
            placeholder={"입학년도 선택"}
          />
          {/* Expected graduation year */}
          <Year 
            formData={formData}
            handleChange={handleChange}
            options={generateGradYearOptions}
            title={"졸업 예정년도"}
            placeholder={"졸업 예정년도 선택"}
          />

          <Terms
            handleTermClick={handleTermClick}
            handleTermOfUseClick={handleTermOfUseClick}
            handleProvisionOfPrivacy={handleProvisionOfPrivacy}
            agreeAll={agreeAll}
            setTermsOfUse={toggleTermOfUse}
            setPrivacy={togglePrivacy}
            setOptionalPrivacy={toggleOptionalPrivacy}
            termOfUse={termOfUse}
            privacy={privacy}
            optionalPrivacy={providePrivacy}
          />

          <button 
            className="Profile--navigate-button basic-info-next-btn"
            disabled={!isValid}
            onClick={registerUser}
            // style={{ marginBottom: "32px" }}
          >
            <span className="h2-18-sb">완료</span>
          </button>
        </div>
        )
      }
      {
        currentPage === 7 && (
          <Privacy
            handleBackButton={handleBackButton}
          />
        )
      }

      {
        currentPage === 8 && (
          <TermsOfUse
            handleBackButton={handleBackButton}
          />
        )
      }
      {
        currentPage === 11 && (
          <ProvisionOfPrivacy
            handleBackButton={handleBackButton}
          />
        )
      }
    </>
  )
}

export default StudentForm;