import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { getCsrf, getIsLogIn } from "../service/getService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const result = await getIsLogIn();
        // console.log("Auth result: ", result);
        setTimeout(() => {
          setIsAuthenticated(result.isSuccess);
          setLoading(false);
        }, 1000)
      } catch (error) {
        console.error("error in checkAuth");
        setLoading(false);
      }
    }

    checkAuthStatus();
  }, []);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const value = useMemo(
    () => ({ isAuthenticated, loading, login, logout }),
    [isAuthenticated, loading]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const OAuthCallback = () => {
  useEffect(() => {
    const handleLogin = async () => {
      const { csrfToken, redirectUri } = await getCsrf();

      console.log(csrfToken)

      if (csrfToken && redirectUri) {
        sessionStorage.setItem("csrf_token", csrfToken);
        // Redirect to the target URL
        window.location.href = "https://www.gvzip.com" + redirectUri;
      }
    }
    handleLogin();
  }, []);

  return <div>Processing login, please wait...</div>;
};
